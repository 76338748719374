@import url("https://fonts.googleapis.com/css?family=Nunito");
@import url("https://fonts.googleapis.com/css?family=Freehand");

body {
  background-color: black;
  color: #ff5e00;
  font-family: "Nunito", sans-serif;
}



.description{
    color: cornsilk;
    width: 58%;
    margin-left: 26rem;
}

.home-img {
  padding: 1rem;
  width: 1678px;
  height: 1000px;
  object-fit: contain;
}
.main-intro {
  text-align: center;
  color: cornsilk;
  font-family: "Nunito", sans-serif;
}

.main-intro-title {
  font-family: "Nunito", sans-serif;
  font-size: 7rem;
  margin-bottom: -8px;
}
.signature {
  width: 90%;
  padding-top: 10px;
}

.picture-style {
  border-radius: 18% 0;
}

.gallery {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;

  padding: 0 12px;
}

.gallery .pics {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  cursor: pointer;
  margin-bottom: 12px;
}

.pics {
  border-radius: 50%;
}

.gallery .pics:hover {
  filter: opacity(0.4);
}

.alt-text {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.726);
  color: rgba(255, 248, 220, 0.74);
  text-align: center;
  font-size: 18px;
  padding: 5px;
  margin-top: -40px;
  opacity: 0;
  transition: opacity 0.5s;
  opacity: 1;
  font-weight: bolder;
}

.alt-text-legend {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  background-color: rgb(0, 0, 0);
  color: cornsilk;
  text-align: center;
  font-size: 18px;
  padding: 5px;
  margin: 0;
  opacity: 0;
  transition: opacity 0.5s;
  opacity: 1;
}

@media (max-width: 2560px) {
   
  
    .home-img {
      width: 1097px;
      height: auto;
      object-fit: contain;
    }
  }

@media (max-width: 1440px) {
 

  .home-img {
    width: 1077px;
    height: auto;
    object-fit: contain;
  }

  .description{
    
    width: 82%;
    margin-left: 8rem;
    text-align: justify;
}
}

@media (max-width: 1024px) {
 

  .home-img {
    width: 767px;
    height: auto;
    object-fit: contain;
  }
  .description{
    
    width: 82%;
    margin-left: 5rem;
    text-align: justify;
}
}

@media (max-width: 991px) {
  .gallery {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }

  

  .home-img {
    width: 569px;
    height: auto;
    object-fit: contain;
  }

  .description{
    
    width: 82%;
    margin-left: 4rem;
    text-align: justify;
}
.main-intro-title {
  font-family: "Nunito", sans-serif;
  font-size: 4rem;
  margin-bottom: -8px;
}
}

@media (max-width: 480px) {
  .gallery {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }

 

  .home-img {
    width: 319px;
    height: 245px;
    object-fit: contain;
  }
  .description{
    
    width: 82%;
    margin-left: 2rem;
    text-align: justify;
}
.main-intro-title {
  font-family: "Nunito", sans-serif;
  font-size: 4rem;
  margin-bottom: -8px;
}
}

@media (max-width: 375px) {
  .gallery {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }

 
  .home-img {
    width: 281px;
    height: 200px;
    object-fit: contain;
  }
  .description{
    
    width: 82%;
    margin-left: 2rem;
    text-align: justify;
}
.main-intro-title {
  font-family: "Nunito", sans-serif;
  font-size: 2rem;
  margin-bottom: -8px;
}
}

/*model*/

.model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  transition: opacity 0.4s ease, visibility 0.4s ease,
    transform 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 999;
}

.model.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}
/* .model img{
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
} */
.model.open svg {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 2rem;
  height: 2rem;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  cursor: pointer;
}

@media (max-width: 374px) {
  .signature {
    width: 49%;
    position: absolute;
    bottom: 11px;
    right: 151px;
  }


  .home-img {
    width: 240px;
    height: 200px;
    object-fit: contain;
  }

  .description{
    
    width: 70%;
    margin-left: 3rem;
    text-align: justify;
}
.main-intro-title {
  font-family:"Nunito", sans-serif; 
  font-size: 2rem;
  margin-bottom: -8px;
}
}
